<template>
    <v-container grid-list-xl fluid>
        <listarCliente v-bind:titulo="'Cargar Carrito de Compra para'"></listarCliente>
        <articulo></articulo>
    </v-container>
</template>

<script>
import Articulo from '@/components/articulo/Articulo.vue';
import clienteService from "@/services/ClienteServices";
import listarCliente from "@/components/cliente/ListarClientes.vue";
export default {
    name: 'NuevoPedido',
    components: {Articulo, listarCliente},
    data() {
        return {
            cliente: {},
            isAdmin: false
        };
    },
    created() {
        this.cliente = clienteService.current();
        this.$bus.$on('SelectorClienteSetCliente', () => {
            this.cliente = clienteService.current();
        });
    }
}
</script>