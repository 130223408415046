const DataTableMixin = {
    data() {
        return {
            rowsperpageitems: [10, 20, 30, 40, 50, 100, 150, 200, { "text": "$vuetify.dataIterator.rowsPerPageAll", "value": -1 }]
        }
    },
    methods: {
        onResize() {
            if ((window.innerWidth - 260) < 768) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        toggleAll() {
            if (this.selected.length) {
                this.selected = []
            } else {
                this.selected = this.desserts.slice()
            }
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        }
    }
}

export default DataTableMixin